import { useEffect, useState } from 'react'

import AdminInnerPageHeader from '../../../section/admin/AdminInnerPageHeader'
import { Column } from '../../../components'
import { Event, GeneralSetting } from '../../../apis'
import { useSelector } from 'react-redux'
import { Select } from '../../../store'
import DataTable from '../../../components/DataTable'

import moment from 'moment'
import { useParams } from 'react-router-dom'

const AdminEventRegistration = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const { data, pagination }: any = useSelector(Select.registrations)
  const event: any = useSelector(Select.event)
  const [params, setParams] = useState({
    pageSize: 10,
    page: !!pagination?.currentPage ? parseInt(pagination?.currentPage) : 1,
    event: id,
  })
  const fetchData = async (params: any) => {
    setLoading(true)
    await Event.getRegistered(params).catch(() => {})
    setLoading(false)
  }

  const getEvent = async () => {
    await Event.find(id).then(() => {
      fetchData({
        ...params,
        event: id,
      })
    })
  }

  useEffect(() => {
    getEvent()
  }, [])
  const columns: any[] = [
    {
      title: 'S.No',
      key: 'sno',
      render: (item: any, index: number) => {
        return index + (pagination.currentPage - 1) * params.pageSize
      },
    },
    {
      title: 'Info',
      key: 'fName',
      render: (item) => {
        return (
          <>
            {item.fName} {item?.lName} <br></br>
            <span>Is Pregant : {!!item?.isPregnant ? 'Yes' : 'No'}</span> &nbsp;
            <span>
              Attend Previous : {!!item?.previousAttend ? 'Yes' : 'No'}
            </span>
            <br></br>
            <span>Delivery Hospital : {item?.deliveryHospital}</span>
            <br></br>
            <span>Doctor Name : {item?.doctorName}</span>
          </>
        )
      },
    },

    {
      title: 'Due Date',
      key: 'dueDate',
      render: (item: any) => {
        return <>{moment(item?.dueDate).format('MMM DD, YYYY')}</>
      },
    },

    {
      title: 'Email',
      key: 'email',
    },
    {
      title: 'Phone No',
      key: 'phoneNo',
    },
  ]
  return (
    <Column>
      <AdminInnerPageHeader
        title="Registration"
        buttonLink="/admin/events"
        buttonTitle="Back"
      />
      <Column
        styleColumn={{
          paddingLeft: 20,
          paddingRight: 20,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Column
          styleColumn={{
            fontSize: 21,
            marginBottom: 20,
            color: '#3AB8B7',
            fontWeight: 'bold',
          }}
        >
          {event?.title}
        </Column>
        <DataTable
          columns={columns}
          data={data}
          paginationProps={{
            totalPages: pagination.totalPages,
            perPage: params.pageSize,
            activePage: params.page,
            handleOnClick: (pageNumber: number) => {
              const updateParams = { ...params, page: pageNumber }
              setParams(updateParams)
              fetchData(updateParams)
            },
          }}
        />
      </Column>
    </Column>
  )
}

export default AdminEventRegistration
