import { Button, Column, FormField, Row, Image } from '../../../components'
import AdminInnerPageHeader from '../../../section/admin/AdminInnerPageHeader'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'
import { Event } from '../../../apis'
import { constants } from '../../../utils'
import moment from 'moment'
const validationShema = yup.object().shape({
  title: yup.string().required('Title is required'),
  location: yup.string().required('Location is required'),
  description: yup.string().required('Description is required'),
  date: yup.date().required('Date is required'),
  startTime: yup.string().required('Start Time is required'),
  endTime: yup.string().required('End Time is required'),
  image: yup
    .mixed()
    .required()
    .test('fileSize', 'File size is too large', function (value: any) {
      if (!value) return true
      return value.size <= constants.FILE_SIZE
    })
    .test('fileFormat', 'Unsupported file format', function (value: any) {
      if (!value) return true
      return constants.SUPPORTED_FORMATS.includes(value.type)
    }),
  type: yup.string().required('Type is required'),
})
const updateValidationShema = yup.object().shape({
  title: yup.string().required('Title is required'),
  location: yup.string().required('Location is required'),
  description: yup.string().required('Description is required'),
  date: yup.date().required('Date is required'),
  startTime: yup.string().required('Start Time is required'),
  endTime: yup.string().required('End Time is required'),
  type: yup.string().required('Type is required'),
  image: yup
    .mixed()
    .nullable()
    .test('fileSize', 'File size is too large', function (value: any) {
      if (!value) return true
      return value.size <= constants.FILE_SIZE
    })
    .test('fileFormat', 'Unsupported file format', function (value: any) {
      if (!value) return true
      return constants.SUPPORTED_FORMATS.includes(value.type)
    }),
})
const AdminEventsForm = () => {
  const { id } = useParams()
  const { state } = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [initialValues, setIntialValues] = useState({
    title: state?.title || null,
    description: state?.description || null,
    image: '',
    location: state?.location || null,
    date: state?.date ? moment(state?.date).format('YYYY-MM-DD') : null,
    startTime: state?.startTime || null,
    endTime: state?.endTime || null,
    type: state?.type || null,
  })
  const handleSubmit = async (values) => {
    setLoading(true)
    const { image, ...restValues } = values
    if (!id) {
      await Event.create(values)
        .then(() => navigate('/admin/events'))
        .catch(() => {})
    } else {
      await Event.update(!!image ? values : restValues, id)
        .then(() => navigate('/admin/events'))
        .catch(() => {})
    }
    setLoading(false)
  }
  return (
    <Column>
      <AdminInnerPageHeader
        title={!!id ? 'Edit Event' : 'Create Event'}
        buttonLink="/admin/events"
        buttonTitle="Back"
      />
      <Column styleColumn="padding:30px 20px;">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={!!id ? updateValidationShema : validationShema}
        >
          {() => {
            return (
              <Form encType="multipart/form-data">
                <Column styleColumn="row-gap:20px;">
                  <FormField
                    name="title"
                    label="Title"
                    placeholder="Title"
                    type="text"
                  />
                  <FormField
                    name="location"
                    label="Location"
                    placeholder="Location"
                    type="text"
                  />
                  <Row
                    styleRow={{
                      width: '100%',
                      justifyContent: 'space-between',
                      columnGap: 10,
                    }}
                  >
                    <Column styleColumn={`width:50%;`}>
                      <FormField name="date" type="date" label="Date" />
                    </Column>
                    <Row styleRow={`width:50%;column-gap:10px;`}>
                      <FormField
                        name="startTime"
                        type="time"
                        label="Start Time"
                        styleWrapper="width:50%;"
                      />
                      <FormField
                        name="endTime"
                        type="time"
                        label="End Time"
                        styleWrapper="width:50%;"
                      />
                    </Row>
                  </Row>
                  <Row
                    styleRow={{
                      width: '100%',
                      justifyContent: 'space-between',
                      columnGap: 10,
                    }}
                  >
                    <Column styleColumn={`width:50%;`}>
                      <FormField
                        name="image"
                        label="Image"
                        placeholder="Image"
                        type="file"
                      />
                    </Column>
                    <Column styleColumn={`width:50%;`}>
                      <FormField
                        label="Type"
                        name="type"
                        type="dropdown"
                        selectProps={{
                          titleAsKey: 'label',
                          valueAsKey: 'value',
                        }}
                        data={[
                          {
                            label: 'Free',
                            value: 'Free',
                          },
                          {
                            label: 'Paid',
                            value: 'Paid',
                          },
                        ]}
                      />
                    </Column>
                  </Row>

                  {!!id && !!state?.image && (
                    <a
                      href={`${process.env.APP_IMAGE_URL}${state?.image}`}
                      target="_blank"
                    >
                      <Image
                        width={50}
                        height={50}
                        src={`${process.env.APP_IMAGE_URL}${state?.image}`}
                      />
                    </a>
                  )}
                  <FormField
                    name="description"
                    label="Description"
                    placeholder="Description"
                    type="editor"
                  />
                  <Row styleRow="justify-content:flex-end">
                    <Button
                      type="submit"
                      title="Submit"
                      height={40}
                      width={120}
                      background={`primary400`}
                      loading={loading}
                    />
                  </Row>
                </Column>
              </Form>
            )
          }}
        </Formik>
      </Column>
    </Column>
  )
}

export default AdminEventsForm
